import { Link } from "react-router-dom"
import classes from "./Common.module.scss"

interface ButtonProps {
    children: JSX.Element
    baseRoute: string
    id: number
}

export const NavigationButton = ({ children, baseRoute, id }: ButtonProps) => (
    <Link className={classes["navigation-wrapper"]} to={`${baseRoute}${id}`}>
        {children}
    </Link>
)
