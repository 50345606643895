import { ClipLoader } from "react-spinners"
import classes from "./Common.module.scss"

interface ButtonProps {
    value: string
    toggleClass: number
    type?: "button" | "submit" | "reset" | undefined
    onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
    disabled?: boolean
}

export const MutableButton = ({ value, onClick, type, toggleClass, disabled }: ButtonProps) => (
    <button
        type={type}
        className={[
            classes["mutable-button"],
            toggleClass === 1 ? classes.bg1 : toggleClass === 2 ? classes.bg2 : classes.bg3,
        ].join(" ")}
        onClick={onClick}
        disabled={disabled}
    >
        {disabled ? <ClipLoader color={"#7F8F83"} loading={disabled} size={25} /> : value}
    </button>
)
