import { useParams } from "react-router-dom"
import classes from "./Common.module.scss"
import { useEffect, useState } from "react"
import { CustomerOutput, getCustomer } from "../../Services/customers.service"
import { ClientCard } from "./Card"
import { ClipLoader } from "react-spinners"
import { AccordionCard } from "./Accordion"
import { CustomerMemo } from "./CustomerMemo"
import { DetailledOrderOutput, getCustomerOrders } from "../../Services/orders.service"
import { ProductionOrder } from "../Order/ProductionOrder"
import { Answers } from "./Anwsers"
import { Radar } from "./Radar"

interface ParamsType {
    userId: string
}

export const ClientOverView = () => {
    const { userId } = useParams<ParamsType>()
    const [loading, setLoading] = useState(false)
    const [user, setUser] = useState({} as CustomerOutput)
    const [paidOrders, setPaidOrders] = useState([] as DetailledOrderOutput[])
    const [analysedOrders, setAnalysedOrders] = useState([] as DetailledOrderOutput[])
    const [producedOrders, setProducedOrders] = useState([] as DetailledOrderOutput[])
    const [deliveredOrders, setDeliveredOrders] = useState([] as DetailledOrderOutput[])

    useEffect(() => {
        setLoading(true)
        const id = parseInt(userId)
        if (!!id) {
            getCustomer(id).then((data) => {
                setUser(data)
                setLoading(false)
            })
        }
    }, [userId])

    useEffect(() => {
        setLoading(true)
        const id = parseInt(userId)
        if (!!id) {
            Promise.all([
                getCustomerOrders(id, "PAID"),
                getCustomerOrders(id, "ANALYSED"),
                getCustomerOrders(id, "PRODUCED"),
                getCustomerOrders(id, "DELIVERED"),
            ]).then((value) => {
                setPaidOrders(value[0])
                setAnalysedOrders(value[1])
                setProducedOrders(value[2])
                setDeliveredOrders(value[3])
            })
        }
    }, [userId])

    return (
        <>
            {!loading && (
                <div className={classes.main}>
                    <ClientCard
                        email={user.email}
                        firstname={user.first_name}
                        lastname={user.last_name}
                        birthdate={user.birthdate}
                        picture={user.skin_picture}
                        phone_number={user.phone_number}
                    />
                    <div className={classes.container}>
                        <AccordionCard name="Note Interne" expanded={true}>
                            <CustomerMemo customerId={user.id} memos={user.internal_memos} />
                        </AccordionCard>
                        <AccordionCard name="Réponses au quizz" expanded={false}>
                            <Answers customerId={user.id} />
                        </AccordionCard>
                        <AccordionCard name="Bilan cutané" expanded={false}>
                            <Radar charts={user.charts || []} />
                        </AccordionCard>
                        {paidOrders.map((order, index) => (
                            <AccordionCard
                                key={index}
                                name={`Fiche de fabrication - commande n°${order.id}`}
                                expanded={true}
                            >
                                <ProductionOrder order={order} readOnly={false} />
                            </AccordionCard>
                        ))}
                        {analysedOrders.length > 0 && <hr style={{ border: "1px solid black" }} />}
                        {analysedOrders.map((order, index) => (
                            <AccordionCard
                                key={index}
                                name={`Commande n°${order.id} en cours de fabrication`}
                                expanded={false}
                            >
                                <ProductionOrder order={order} readOnly={true} />
                            </AccordionCard>
                        ))}
                        {producedOrders.length > 0 && <hr style={{ border: "1px solid black" }} />}
                        {producedOrders.map((order, index) => (
                            <AccordionCard
                                key={index}
                                name={`Commande n°${order.id} en cours de livraison`}
                                expanded={false}
                            >
                                <ProductionOrder order={order} readOnly={true} />
                            </AccordionCard>
                        ))}
                        {deliveredOrders.length > 0 && <hr style={{ border: "1px solid black" }} />}
                        {deliveredOrders.map((order, index) => (
                            <AccordionCard
                                key={index}
                                name={`Commande n°${order.id} livrée`}
                                expanded={false}
                            >
                                <ProductionOrder order={order} readOnly={true} />
                            </AccordionCard>
                        ))}
                    </div>
                </div>
            )}
            {loading && (
                <div className={classes.loading}>
                    <div>
                        <ClipLoader color={"#7F8F83"} loading={loading} size={50} />
                    </div>
                </div>
            )}
        </>
    )
}
