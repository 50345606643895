import { useState } from "react"
import { Alert } from "react-bootstrap"
import moment from "moment"
import {
    deleteOrderItem,
    DetailledOrderOutput,
    updateOrder,
    updateOrderItem,
    UpdateOrderItemInput,
} from "../../Services/orders.service"
import { MutableButton } from "../Button/MutableButton"
import { Message } from "../Client/Message"
import { DatePickerInput } from "../Input/DatePickerInput"
import { SelectInput } from "../Input/SelectInput"
import { ProductionItem } from "../Item/ProductionItem"
import classes from "./ProductionOrder.module.scss"
import { updateCustomerDiag } from "../../Services/customers.service"

interface ProductionOrderProps {
    order: DetailledOrderOutput
    readOnly: boolean
}

interface ItemsToUpdate {
    itemId: number
    data: UpdateOrderItemInput
}

export const ProductionOrder = ({ order, readOnly }: ProductionOrderProps) => {
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)
    const [message, setMessage] = useState("")
    const [readonly, setReadonly] = useState(readOnly)
    const [pharmacistComment, setPharmacistComment] = useState(order.pharmacist_comment)
    const [disabled, setDisabled] = useState(false)
    const [shop, setShop] = useState({
        label: order.preparation_place,
        value: order.preparation_place,
    })
    const [plannedDate, setPlannedDate] = useState(
        order.planned_date ? moment(order.planned_date).toDate() : null
    )
    const [orderData, setDataOrder] = useState(order)
    const [itemToUpdate, setItemToUpdate] = useState([] as ItemsToUpdate[])

    const handleOnUpdate = () => {
        setDisabled(true)
        updateCustomerDiag(order.customer.id).then(() => {
            setDisabled(false)
            window.location.reload()
        })
    }

    const handleMessage = (cb: React.Dispatch<React.SetStateAction<boolean>>, message: string) => {
        cb(true)
        setMessage(message)
        setTimeout(() => {
            cb(false)
        }, 3000)
    }

    const handleCloseItem = (id: number) => {
        return () => {
            deleteOrderItem(id).then(() => {
                setDataOrder({
                    ...orderData,
                    order_items: orderData.order_items.filter((item) => item.id !== id),
                })
            })
        }
    }

    const handleChangeItem = (id: number) => {
        return (data: UpdateOrderItemInput) => {
            setItemToUpdate([
                ...itemToUpdate.filter((item) => item.itemId !== id),
                { itemId: id, data },
            ])
        }
    }

    const handleOnSave = async () => {
        try {
            setDisabled(true)
            for (let item of itemToUpdate) {
                await updateOrderItem(item.itemId, item.data)
            }
            await updateOrder({
                id: order.id,
                preparation_place: shop.value,
                pharmacist_comment: pharmacistComment,
                planned_date: plannedDate ? moment(plannedDate).format("YYYY-MM-DD") : null,
            })
            setItemToUpdate([])
            setDisabled(false)
            handleMessage(setSuccess, "Order successfully saved")
        } catch (e) {
            const key = Object.keys(e.response?.data)?.[0]
            const message = !!key ? key + " : " + e.response.data[key] : "Unknown error"
            handleMessage(setError, message)
        }
    }

    const handleOnSend = async (urgent: boolean = false) => {
        try {
            setDisabled(true)
            for (let item of itemToUpdate) {
                await updateOrderItem(item.itemId, item.data)
            }
            await updateOrder({
                id: order.id,
                status: "ANALYSED",
                preparation_place: shop.value,
                pharmacist_comment: pharmacistComment,
                urgent,
                planned_date: plannedDate ? moment(plannedDate).format("YYYY-MM-DD") : null,
            })
            setItemToUpdate([])
            setDisabled(false)
            setReadonly(true)
            handleMessage(setSuccess, "Order successfully sent")
        } catch (e) {
            const key = Object.keys(e.response?.data)?.[0]
            const message = !!key ? key + " : " + e.response.data[key] : "Unknown error"
            handleMessage(setError, message)
        }
    }

    const handleDiscard = async () => {
        setItemToUpdate([])
        window.location.reload()
    }

    const handleChangeComment: React.ChangeEventHandler<HTMLTextAreaElement> = async (e) => {
        if (e.target.value === "") setPharmacistComment(null)
        setPharmacistComment(e.target.value)
    }

    return (
        <div className={classes.main}>
            {!!success && (
                <Alert variant="success" className={classes.alert}>
                    {message}
                </Alert>
            )}
            {!!error && (
                <Alert variant="danger" className={classes.alert}>
                    {message}
                </Alert>
            )}
            {order.customer_comment && (
                <div className={classes.comment}>
                    <span> Commentaire client : </span>
                    <Message readOnly={true} deletable={false} value={order.customer_comment} />
                </div>
            )}
            {orderData.order_items.map((item) => (
                <ProductionItem
                    key={item.id}
                    item={item}
                    handleCloseItem={handleCloseItem(item.id)}
                    handleChangeItem={handleChangeItem(item.id)}
                    readOnly={readonly}
                />
            ))}

            <div className={classes.comment}>
                <span> Commentaire pharmacien : </span>
                <textarea
                    onChange={handleChangeComment}
                    placeholder="Ecrire votre message"
                    value={pharmacistComment || ""}
                />
            </div>
            {!readonly && (
                <>
                    <SelectInput
                        label="Centre de production"
                        options={[
                            { label: "KELLER", value: "KELLER" },
                            { label: "MADAME", value: "MADAME" },
                        ]}
                        option={shop}
                        readOnly={false}
                        onChange={setShop}
                    />
                    <DatePickerInput
                        label="Chez le client le "
                        onChange={(date) => {
                            setPlannedDate(date as Date | null)
                        }}
                        date={plannedDate}
                    />
                    <MutableButton
                        value="Mettre à jour le diagnostic"
                        toggleClass={1}
                        onClick={handleOnUpdate}
                        disabled={disabled}
                    />
                    <MutableButton
                        value="Enregistrer"
                        toggleClass={1}
                        onClick={handleOnSave}
                        disabled={disabled}
                    />
                    <MutableButton
                        value="Envoyer au labo"
                        toggleClass={1}
                        onClick={() => handleOnSend()}
                        disabled={disabled}
                    />
                    <MutableButton
                        value="Envoyer en priotité"
                        toggleClass={3}
                        onClick={() => handleOnSend(true)}
                        disabled={disabled}
                    />
                    <MutableButton
                        value="Annuler"
                        toggleClass={2}
                        onClick={handleDiscard}
                        disabled={false}
                    />
                </>
            )}
        </div>
    )
}
