import React, { useState } from "react"
import Select, { OptionTypeBase } from "react-select"
import classes from "./Common.module.scss"
import { updateOrder } from "../../Services/orders.service"
import { StatusOption } from "./variable/statusOption"

interface StatusInputProps {
    orderId: number
    statusObject: OptionTypeBase
}

export const SelectStatus = ({ statusObject, orderId }: StatusInputProps) => {
    const [selectedOption, setSelectedOption] = useState(statusObject)

    const handleChange = async (selectedOption: any) => {
        setSelectedOption(selectedOption)
        try {
            await updateOrder({ id: orderId, status: selectedOption.value })
        } catch (e) {
            console.log(e)
        }
    }
    return (
        <Select
            value={selectedOption}
            options={StatusOption}
            onChange={handleChange}
            className={classes.color}
            styles={{
                container: (provided) => ({ ...provided, width: "200px" }),
                menuList: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                    backgroundColor: "white",
                    width: "200px",
                }),
            }}
        />
    )
}
