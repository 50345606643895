import React, { useState } from "react"
import moment from "moment"
import { debounce } from "lodash"
import classes from "./Common.module.scss"
import Paper from "@material-ui/core/Paper"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TablePagination from "@material-ui/core/TablePagination"
import TableRow from "@material-ui/core/TableRow"
import { useEffect } from "react"
import { CustomerOutput } from "../../Services/customers.service"
import { SearchInput } from "../Input/SearchInput"
import { Ordering } from "../CustomInput/Ordering"
import { Label } from "./interfaces/table.interfaces"
import {
    getOrdersList,
    LightItemOutput,
    OrderOutput,
    Shipping,
    Status,
} from "../../Services/orders.service"
import { SelectStatus } from "../CustomInput/Status"
import { capitalize } from "../../utils/utils"
import { ClipLoader } from "react-spinners"
import { getOrderingField } from "./utils/ordering.util"
import { Filtering } from "../CustomInput/Filtering"
import { StatusOption } from "../CustomInput/variable/statusOption"
import { OptionTypeBase } from "react-select"
import { LightItem } from "../Item/LightItem"
import { SanitizeHTML } from "../RawHTML/RawHTML"
import { QuestionMark } from "../Icon/QuestionMark"
import { useShop } from "../../Contexts/shop.context"
import { NavigationButton } from "../Button/NavigationButton"
import { MutableButton } from "../Button/MutableButton"
import { useMemo } from "react"
import { ShippingOption } from "../CustomInput/variable/shippingOption"

type OrderingType = {
    [key in keyof OrderOutput]?: "ASC" | "DESC"
}

type FilteringType = { status?: Status; shipping_method?: Shipping }

const columns: Label<OrderOutput>[] = [
    {
        id: "customer",
        label: "Clients",
        align: "center",
        minWidth: 150,
        format: (value: CustomerOutput, id: number) =>
            [value.first_name, value.last_name].join(" "),
    },
    {
        id: "pharmacist_comment",
        label: "&quest;",
        align: "center",
        minWidth: 20,
        format: (value: string, id: number) => (!!value ? <QuestionMark helpText={value} /> : null),
    },
    {
        id: "shipping_method",
        label: "Mode de livraison",
        align: "center",
        width: 150,
    },
    {
        id: "preparation_place",
        label: "Lieu de préparation",
        align: "center",
        width: 120,
    },
    {
        id: "sales_channel",
        label: "Canal de vente",
        align: "center",
        width: 120,
        minWidth: 100,
    },
    {
        id: "discount_code",
        label: "Réduc",
        align: "center",
        width: 80,
    },
    {
        id: "status",
        label: "Status",
        align: "center",
        width: 220,
        format: (value: Status, id: number) => (
            <>
                <SelectStatus orderId={id} statusObject={{ value, label: capitalize(value) }} />
                <MutableButton
                    value="Impression"
                    toggleClass={1}
                    onClick={() => {
                        window.open(
                            process.env.REACT_APP_DOMAIN + `/api/prescription/pdf/${id}`,
                            "_blank"
                        )
                    }}
                />
            </>
        ),
    },
    {
        id: "order_items",
        label: "Produit",
        align: "center",
        minWidth: 270,
        format: (value: LightItemOutput[], id: number) =>
            value.map((item) => (
                <LightItem
                    id={item.id}
                    kind={item.kind}
                    key={item.id}
                    name={item.name}
                    synchronized={item.synchronized}
                    given={item.given}
                    quantity={item.quantity}
                    packaging={item.packaging}
                    weightOrQuantity={item.weight_or_quantity}
                    easyprepName={item.easyprep_name}
                    perfume={item.perfume}
                />
            )),
    },
    {
        id: "shipping_date",
        label: "Sortie du labo",
        align: "center",
        width: 80,
        format: (value: Date, id: number) => (value ? moment(value).format("DD/MM/YY") : null),
    },
    {
        id: "planned_date",
        label: "Réception par client",
        align: "center",
        width: 80,
        format: (value: Date, id: number) => (value ? moment(value).format("DD/MM/YY") : null),
    },
    { id: "price", label: "Prix (€)", align: "center", minWidth: 100 },
]

const orderingLabel = [
    { label: "client", keyValue: "customer__first_name" },
    { label: "Réception par client", keyValue: "planned_date" },
    { label: "Sortie du labo", keyValue: "shipping_date" },
    { label: "urgent", keyValue: "urgent" },
]

export default function OrdersTable() {
    const { shopContext } = useShop()
    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(5)
    const [count, setCount] = useState(0)
    const [ordersList, setOrdersList] = useState([] as OrderOutput[])
    const [searchField, setSearchField] = useState("" as string)
    const [filtering, setFilterField] = useState({} as FilteringType)
    const [ordering, setOrdering] = useState({
        urgent: "DESC",
        shipping_date: "ASC",
    } as OrderingType)

    useEffect(() => {
        setLoading(true)
        const preparation_place = shopContext ? { preparation_place: shopContext } : {}
        getOrdersList({
            page,
            page_size: rowsPerPage,
            search: searchField,
            ordering: getOrderingField<OrderingType>(ordering),
            ...filtering,
            ...preparation_place,
        }).then((data) => {
            setOrdersList(data.results)
            setCount(data.count)
            setLoading(false)
        })
    }, [page, rowsPerPage, searchField, ordering, filtering, shopContext])

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
        newPage: number
    ) => {
        if (!!event) {
            setPage(newPage + 1)
        }
    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void => {
        if (!!event) {
            setRowsPerPage(+event.target.value)
            setPage(1)
        }
    }

    const handleChangeOrdering = (partialOrdering: Partial<OrderingType>) => {
        setOrdering({ ...partialOrdering })
    }

    const handleChangeFiltering =
        (type: "shipping_method" | "status") => (option: OptionTypeBase | null) => {
            setPage(1)
            const newFiltering: FilteringType = { ...filtering }
            if (option?.value) {
                newFiltering[type] = option?.value
                setFilterField(newFiltering)
            } else {
                delete newFiltering[type]
                setFilterField(newFiltering)
            }
        }

    const handleChangeSearchField = (value: string) => {
        setSearchField(value)
        setPage(1)
    }

    const debouncedChangeHandler = useMemo(() => debounce(handleChangeSearchField, 300), [])

    return (
        <>
            <Paper className={classes.wrapper}>
                <h1> Liste de commandes</h1>
                <div className={classes["top-header"]}>
                    <SearchInput
                        handleChangeSearchField={debouncedChangeHandler}
                        placeholder={"Client ou id"}
                    />
                    <Filtering
                        options={StatusOption}
                        label="status"
                        width={250}
                        filterBy={handleChangeFiltering("status")}
                    />
                    <Filtering
                        options={ShippingOption}
                        label="shipping"
                        width={250}
                        filterBy={handleChangeFiltering("shipping_method")}
                    />
                    <div className={classes.group}>
                        {orderingLabel.map((elt) => (
                            <Ordering
                                sortBy={handleChangeOrdering}
                                label={elt.label}
                                keyValue={elt.keyValue}
                                key={elt.keyValue}
                                initialState={elt.keyValue === "urgent" ? "DESC" : "ASC"}
                            />
                        ))}
                    </div>
                </div>
                <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead className={classes["background-head"]}>
                            <TableRow className={classes["background-head"]}>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                        className={classes["background-head"]}
                                    >
                                        <SanitizeHTML html={column.label} />
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        {!loading && (
                            <TableBody className={classes["background-body"]}>
                                {ordersList.map((row) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.id}
                                            className={classes["background-body"]}
                                        >
                                            {columns.map((column) => {
                                                const value = row[column.id]
                                                return (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        className={classes["background-body"]}
                                                        style={
                                                            row.urgent
                                                                ? {
                                                                      backgroundColor:
                                                                          "rgba(226, 106, 106, 0.2)",
                                                                  }
                                                                : {}
                                                        }
                                                    >
                                                        {[
                                                            "price",
                                                            "creation_date",
                                                            "customer",
                                                            "shipping_date",
                                                            "delivery_date",
                                                        ].includes(column.id) ? (
                                                            <NavigationButton
                                                                baseRoute={"/orders/"}
                                                                id={row.id}
                                                            >
                                                                <>
                                                                    {column.format
                                                                        ? column.format(
                                                                              value,
                                                                              row.id
                                                                          )
                                                                        : value}
                                                                </>
                                                            </NavigationButton>
                                                        ) : (
                                                            <>
                                                                {column.format
                                                                    ? column.format(value, row.id)
                                                                    : value}
                                                            </>
                                                        )}
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
                {!loading && (
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 15, 20]}
                        component="div"
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page - 1}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                )}
                {loading && (
                    <div className={classes.loading}>
                        <div>
                            <ClipLoader color={"#7F8F83"} loading={loading} size={50} />
                        </div>
                    </div>
                )}
            </Paper>
        </>
    )
}
