import React, { useState } from "react"
import Paper from "@material-ui/core/Paper"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TablePagination from "@material-ui/core/TablePagination"
import TableRow from "@material-ui/core/TableRow"
import { useEffect } from "react"
import { CustomerOutput, getCustomersList } from "../../Services/customers.service"
import classes from "./Common.module.scss"
import { SearchInput } from "../Input/SearchInput"
import { Ordering } from "../CustomInput/Ordering"
import { Label } from "./interfaces/table.interfaces"
import { getOrderingField } from "./utils/ordering.util"
import { ClipLoader } from "react-spinners"
import { NavigationButton } from "../Button/NavigationButton"

type OrderingType = {
    [key in keyof CustomerOutput]?: "ASC" | "DESC"
}

const columns: Label<CustomerOutput>[] = [
    {
        id: "gender",
        label: "Genre",
        align: "center",
        minWidth: 20,
        format: (value: "MALE" | "FEMALE") => (value === "FEMALE" ? "Mme" : "M"),
    },
    { id: "first_name", label: "Prénom", align: "center", minWidth: 200 },
    { id: "last_name", label: "Nom", align: "center", minWidth: 200 },
    { id: "email", label: "Email", align: "center", minWidth: 200 },
]

const orderingLabel = [
    { label: "Email", keyValue: "email" },
    { label: "Prénom", keyValue: "first_name" },
    { label: "Nom", keyValue: "last_name" },
]

export default function CustomersTable() {
    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [count, setCount] = useState(0)
    const [customersList, setCustomersList] = useState([] as CustomerOutput[])
    const [searchField, setSearchField] = useState("" as string)
    const [ordering, setOrdering] = useState({} as OrderingType)

    useEffect(() => {
        setLoading(true)
        getCustomersList({
            page,
            page_size: rowsPerPage,
            search: searchField,
            ordering: getOrderingField<OrderingType>(ordering),
        }).then((data) => {
            setCustomersList(data.results)
            setCount(data.count)
            setLoading(false)
        })
    }, [page, rowsPerPage, searchField, ordering])

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
        newPage: number
    ) => {
        if (!!event) {
            setPage(newPage + 1)
        }
    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void => {
        if (!!event) {
            setRowsPerPage(+event.target.value)
            setPage(1)
        }
    }

    const handleChangeOrdering = (partialOrdering: Partial<OrderingType>) => {
        setOrdering({ ...partialOrdering })
    }

    const handleChangeSearchField = (value: string) => {
        setSearchField(value)
        setPage(1)
    }

    return (
        <Paper className={classes.wrapper}>
            <h1> Liste clients</h1>
            <div className={classes["top-header"]}>
                <SearchInput
                    handleChangeSearchField={handleChangeSearchField}
                    placeholder={"email ou nom"}
                />
                <div className={classes.group}>
                    {orderingLabel.map((elt) => (
                        <Ordering
                            sortBy={handleChangeOrdering}
                            label={elt.label}
                            keyValue={elt.keyValue}
                            key={elt.keyValue}
                            initialState="ASC"
                        />
                    ))}
                </div>
            </div>
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead className={classes["background-head"]}>
                        <TableRow className={classes["background-head"]}>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                    className={classes["background-head"]}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    {!loading && (
                        <TableBody className={classes["background-body"]}>
                            {customersList.map((row) => {
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={row.id}
                                        className={classes["background-body"]}
                                    >
                                        {columns.map((column) => {
                                            const value = row[column.id]
                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                    <NavigationButton
                                                        baseRoute={"/clients/"}
                                                        id={row.id}
                                                    >
                                                        <>
                                                            {column.format
                                                                ? column.format(value, row.id)
                                                                : value}
                                                        </>
                                                    </NavigationButton>
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    )}
                </Table>
            </TableContainer>
            {!loading && (
                <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 20]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page - 1}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            )}
            {loading && (
                <div className={classes.loading}>
                    <div>
                        <ClipLoader color={"#7F8F83"} loading={loading} size={50} />
                    </div>
                </div>
            )}
        </Paper>
    )
}
