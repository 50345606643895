import { CustomerOutput, LightCustomerOutput } from "./customers.service"
import { PaginationInterface } from "./paginations.interface"
import { ActionResult } from "./type/action.type"
import { get, patch, post, del } from "./utils.service"

export type Status = "PAID" | "ANALYSED" | "PRODUCED" | "DELIVERED"
export type Shipping =
    | "PICKUP_MADAME"
    | "PICKUP_KELLER"
    | "COLISSIMO"
    | "COURSIER"
    | "MONDIAL_RELAY"
export interface OrdersListInput {
    page: number
    page_size: number
    ordering?: string
    search?: string
    status?: string
    preparation_place?: "KELLER" | "MADAME"
    sales_channel?: "ESHOP" | "RETAIL_MADAME" | "RETAIL_KELLER"
}

export interface LightItemOutput {
    id: number
    kind: "product" | "product_page" | "accessory"
    name: string
    synchronized: boolean
    given: boolean
    quantity: number
    packaging: string | null
    easyprep_name: string | null
    weight_or_quantity: number | null
    perfume: string | null
}

export interface OrderInterface {
    id: number
    customer: LightCustomerOutput
    shipping_method: string
    pharmacist_comment: string | null
    customer_comment: string | null
    urgent: boolean
    status: Status
    shipping_date: Date
    planned_date: Date | null
    price: number
    preparation_place: "KELLER" | "MADAME"
    sales_channel?: "ESHOP" | "RETAIL_MADAME" | "RETAIL_KELLER"
    discount_code: string | null
    perfume: string
}
export interface OrderOutput extends OrderInterface {
    order_items: LightItemOutput[]
}

export interface DetailledOrderOutput extends OrderInterface {
    order_items: ItemOutput[]
    customer: CustomerOutput
    recommanded_ingredients: { id: number; name_fr: string; picture: string }[]
}

export interface UpdateOrderInput {
    id: number
    status?: Status
    preparation_place?: "KELLER" | "MADAME"
    pharmacist_comment?: string | null
    urgent?: boolean
    planned_date?: string | null
}
export interface ItemOutput extends LightItemOutput {
    price: number
    quantity: number
    product: null | { id: number; title: string }
    product_page: null | {
        id: number
        title_fr: string
        title_en: string
        product: { id: number; title: string }
        benefits: { name_fr: string; picture: string }[]
    }
    variant: null | {
        id: number
        weight: number | null
        packaging: string
        quantity: number | null
        picture: string
    }
    reference: null | { id: number; texture_en: string; easyprep_name: string }
    ingredient_perfume: null | { id: number; name_fr: string }
    ingredients: {
        id: number
        name_fr: string
        picture: string
        is_history?: boolean
    }[]
    recommanded_ingredients: number[]
    history_ingredients: number[]
    accessory: { id: string; title_fr: string; title_en: string; images: { picture: string }[] }
}

export interface UpdateOrderItemInput {
    variant_id: number
    reference_id: number
    perfume_id?: number
    ingredients_ids_list: number[]
}

export type OrdersListOutput = PaginationInterface<OrderOutput>

export const getOrdersList = async (input: OrdersListInput) => {
    const response = await get("/api/admin-order/", input)
    return response.data as OrdersListOutput
}

export const updateOrder = async (input: UpdateOrderInput) => {
    const { id, ...data } = input
    const response = await patch(`/api/admin-order/${id}/`, data)
    return response.data as OrderOutput
}

export const synchronizeOrderItem = async (orderItemId: number) => {
    const response = await post(`/api/admin-order-item/${orderItemId}/synchronize/`)
    return response as ActionResult
}

export const giveOrderItem = async (orderItemId: number) => {
    const response = await post(`/api/admin-order-item/${orderItemId}/give/`)
    return response as ActionResult
}

export const desynchronizeOrderItem = async (orderItemId: number) => {
    const response = await post(`/api/admin-order-item/${orderItemId}/desynchronize/`)
    return response as ActionResult
}

export const getCardItem = async (orderItemId: number) => {
    const response = await get(`/api/admin-order-item/${orderItemId}/`)
    return response.data as ItemOutput
}

export const getCustomerOrder = async (orderId: number) => {
    const response = await get(`/api/admin-custom-order/${orderId}/`)
    return response.data as DetailledOrderOutput
}

export const getCustomerOrders = async (customerId: number, status: Status) => {
    const response = await get(`/api/admin-custom-order/get-customer-orders/`, {
        customerId,
        status,
    })
    return response.data as DetailledOrderOutput[]
}

export const updateOrderItem = async (orderItemId: number, data: UpdateOrderItemInput) => {
    const response = await patch(`/api/admin-order-item/${orderItemId}/update/`, data)
    return response.data as DetailledOrderOutput[]
}

export const deleteOrderItem = async (orderItemId: number) => {
    const response = await del(`/api/admin-order-item/${orderItemId}`)
    return response.data
}
