import DatePicker, { registerLocale } from "react-datepicker"
import classes from "./DatePickerInput.module.scss"
import "react-datepicker/dist/react-datepicker.css"
import fr from "date-fns/locale/fr"
registerLocale("fr", fr)

interface InputProps {
    date: Date | null | undefined
    label: string
    onChange: (
        date: Date | [Date | null, Date | null] | null,
        event: React.SyntheticEvent<any, Event> | undefined
    ) => void
}

export const DatePickerInput = ({ date, label, onChange }: InputProps) => (
    <div className={classes["container"]}>
        <label>{label}</label>
        <DatePicker
            selected={date}
            onChange={onChange}
            placeholderText={"Choose a date"}
            locale="fr"
            dateFormat="dd/MM/yyyy"
        />
    </div>
)
