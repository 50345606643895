import moment from "moment"
import "moment/locale/fr"
import { FormEvent } from "react"
import { MutableButton } from "../Button/MutableButton"
import DeleteIcon from "@material-ui/icons/Delete"
import classes from "./Common.module.scss"

interface MessageProps {
    id?: number
    readOnly: boolean
    deletable: boolean
    writtenAt?: number
    value?: string
    handleSubmit?: React.FormEventHandler<HTMLFormElement> | undefined
    onChange?: React.ChangeEventHandler<HTMLTextAreaElement> | undefined
    removeMemo?: (id: number) => void
    onClick?: React.MouseEventHandler<HTMLButtonElement>
}

export const Message = ({
    writtenAt,
    value,
    readOnly,
    deletable,
    id,
    handleSubmit,
    onChange,
    removeMemo,
    onClick,
}: MessageProps) => {
    const localWrittenAt = moment.unix(writtenAt || 0)
    localWrittenAt.locale("fr")

    const handleFormSublmit = (e: FormEvent<HTMLFormElement>) => {
        if (handleSubmit) handleSubmit(e)
    }

    const handleDelete = () => {
        if (removeMemo && id) removeMemo(id)
    }

    return (
        <div className={classes.message}>
            {readOnly ? (
                <div>
                    {writtenAt && <p>{localWrittenAt.format("DD/MM à HH:mm")}</p>}
                    {deletable && (
                        <button onClick={handleDelete}>
                            <DeleteIcon />
                        </button>
                    )}
                </div>
            ) : null}

            <form onSubmit={handleFormSublmit}>
                {!readOnly ? (
                    <>
                        <textarea onChange={onChange} placeholder="Ecrire votre message" />
                        <div>
                            <MutableButton
                                value="Ajouter le commentaire"
                                type="submit"
                                toggleClass={1}
                                disabled={false}
                            />
                            <MutableButton
                                value="Annuler"
                                type="button"
                                toggleClass={2}
                                onClick={onClick}
                                disabled={false}
                            />
                        </div>
                    </>
                ) : (
                    <textarea value={value} style={{ height: "auto" }} readOnly />
                )}
            </form>
        </div>
    )
}
