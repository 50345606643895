import { PaginationInterface } from "./paginations.interface"
import { IngredientOutput, ReferenceOutput } from "./reference.service"
import { get } from "./utils.service"

export interface VariantOutput {
    id: number
    weight: number | null
    quantity: number | null
    packaging: string
    excluded_references: ReferenceOutput[]
}

export interface LightProductOutput {
    id: number
    title: string
}
export interface ProductOutput extends LightProductOutput {
    max_benefits: number
    references: ReferenceOutput[]
    variants: VariantOutput[]
    perfumes: IngredientOutput[]
}

export type CustomersList = PaginationInterface<ReferenceOutput>

export const getProduct = async (id: number) => {
    const response = await get(`/api/admin-product/${id}/`)
    return response.data as ProductOutput
}
