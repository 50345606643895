import classes from "./Card.module.scss"

interface IngredientCardProps {
    picture: string
    name: string
}

export const IngredientCard = ({ picture, name }: IngredientCardProps) => {
    return (
        <div className={classes.main}>
            <div className={classes.container}>
                <img src={picture} alt="" />
                <p>{name}</p>
            </div>
        </div>
    )
}
