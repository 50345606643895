import api, { ACCESS_TOKEN } from "./api.service"

// Without passing token for raw request
export const rawPost = async (route: string, object = {}) => {
    return await api.post(route, object)
}

export const get = async (route: string, params = {}) => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN)
    return await api.get(route, {
        params,
        headers: { Authorization: `Bearer ${accessToken}` },
    })
}

export const post = async (route: string, object = {}) => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN)
    return await api.post(route, object, { headers: { Authorization: `Bearer ${accessToken}` } })
}

export const put = async (route: string, object = {}) => {
    return await api.put(route, object)
}

export const patch = async (route: string, object = {}) => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN)
    return await api.patch(route, object, {
        headers: { Authorization: `Bearer ${accessToken}` },
    })
}

export const del = async (route: string) => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN)
    return await api.delete(route, { headers: { Authorization: `Bearer ${accessToken}` } })
}
