import React, { useState } from "react"
import SyncIcon from "@material-ui/icons/Sync"
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket"
import SyncDisabledIcon from "@material-ui/icons/SyncDisabled"
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn"
import ReplayIcon from "@material-ui/icons/Replay"
import classes from "./Common.module.scss"
import {
    desynchronizeOrderItem,
    giveOrderItem,
    synchronizeOrderItem,
} from "../../Services/orders.service"
import { ItemCard } from "./Card"

interface LightItemIterface {
    id: number
    kind: "product" | "product_page" | "accessory"
    name: string
    synchronized: boolean
    given: boolean
    quantity: number
    packaging?: string | null
    weightOrQuantity?: number | null
    easyprepName?: string | null
    perfume?: string | null
}

export const LightItem = ({
    id,
    name,
    kind,
    synchronized,
    given,
    quantity,
    packaging,
    weightOrQuantity,
    easyprepName,
    perfume,
}: LightItemIterface) => {
    const [display, setDisplay] = useState(false)
    const [isSynchronized, setIsSynchronized] = useState(synchronized)
    const [isGiven, setIsGiven] = useState(given)
    const [rotate, setRotate] = useState(false)

    const show = !!(packaging || weightOrQuantity || easyprepName)

    const handleSyncClick = () => {
        if (!isSynchronized) {
            setRotate(true)
            synchronizeOrderItem(id).then((response) => {
                if (response.status === 200) {
                    setIsSynchronized(true)
                }
                setRotate(false)
            })
        }
    }

    const handleGiveClick = () => {
        if (!isGiven) {
            giveOrderItem(id).then((response) => {
                if (response.status === 200) {
                    setIsGiven(true)
                }
                setRotate(false)
            })
        }
    }

    const handleDiscardClick = () => {
        if (isSynchronized || isGiven) {
            desynchronizeOrderItem(id).then((response) => {
                if (response.status === 200) {
                    setIsSynchronized(false)
                    setIsGiven(false)
                }
            })
        }
    }

    const handleClose = () => {
        setDisplay(false)
    }

    return (
        <div
            className={[classes.main, classes[isSynchronized || isGiven ? "green" : "orange"]].join(
                " "
            )}
        >
            <ItemCard itemId={id} display={display} handleClose={handleClose} />
            <button
                onClick={(e) => {
                    setDisplay(true)
                }}
                className={classes.title}
            >
                <span style={{ fontSize: 20 }}>{name}</span>
                {show && (
                    <span>
                        {packaging?.toLowerCase()}&nbsp; {weightOrQuantity}&nbsp;
                        {easyprepName?.toLowerCase()}&nbsp; {perfume}&nbsp;
                    </span>
                )}
            </button>
            <span className={classes.quantity}>{quantity}</span>
            {!isGiven && kind !== "accessory" && (
                <button onClick={handleSyncClick} className={rotate ? classes.rotate : undefined}>
                    {isSynchronized ? <SyncDisabledIcon /> : <SyncIcon />}
                </button>
            )}
            {!isSynchronized && (
                <button onClick={handleGiveClick}>
                    {isGiven ? <AssignmentTurnedInIcon /> : <ShoppingBasketIcon />}
                </button>
            )}
            {(isSynchronized || isGiven) && (
                <button onClick={handleDiscardClick}>
                    <ReplayIcon />
                </button>
            )}
        </div>
    )
}
