import { get, rawPost } from "./utils.service"

export interface LoginInput {
    email: string
    password: string
}

export interface LoginOutput {
    access_token: string
    refresh_token: string
    user: {
        pk: number
        email: string
        first_name: string
        last_name: string
    }
}

export interface UserInfoOutput {
    id: number
    email: string
    first_name: string
    last_name: string
}

export const loginUser = async (credentials: LoginInput) => {
    const response = await rawPost("/api/login/", credentials)
    return response.data as LoginOutput
}

export const getUserInfo = async () => {
    const response = await get("/api/admin-user/get-me/")
    return response.data as UserInfoOutput
}
