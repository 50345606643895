import React, { useState } from "react"
import { IconButton } from "@material-ui/core"
import { ExpandMore, ExpandLess } from "@material-ui/icons"
import classes from "./Ordering.module.scss"

interface OrderingInputProps {
    label: string
    keyValue: string
    initialState: "ASC" | "DESC"
    sortBy: (ascending: any) => void
}

export const Ordering = ({ label, keyValue, sortBy, initialState }: OrderingInputProps) => {
    const [ordering, setOrdering] = useState({ [keyValue]: initialState } as {
        [key: string]: "ASC" | "DESC"
    })
    const onClick = () => {
        if (ordering[keyValue] === "ASC") {
            setOrdering({ [keyValue]: "DESC" })
            sortBy({ [keyValue]: "DESC" })
        } else {
            setOrdering({ [keyValue]: "ASC" })
            sortBy({ [keyValue]: "ASC" })
        }
    }

    return (
        <div onClick={() => onClick()} className={classes.main}>
            <span>{label}</span>
            <IconButton size="small">
                {ordering[keyValue] === "ASC" ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
        </div>
    )
}
