import React from "react"
import { useState } from "react"
import classes from "./SearchInput.module.scss"

interface SearchInputProps {
    handleChangeSearchField: (value: string) => void
    placeholder: string
}

export const SearchInput = ({ handleChangeSearchField, placeholder }: SearchInputProps) => {
    const [value, setValue] = useState("")
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        handleChangeSearchField(event.target.value)
        setValue(event.target.value)
    }

    return (
        <div className={classes.container}>
            <input type="text" placeholder={placeholder} value={value} onChange={handleChange} />
        </div>
    )
}
