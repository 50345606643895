import { useState } from "react"
import { useEffect } from "react"
import { getCardItem, ItemOutput } from "../../Services/orders.service"
import { IngredientCard } from "../Ingredient/Card"
import classes from "./Common.module.scss"

interface ItemCardProps {
    display: boolean
    itemId: number
    handleClose: React.MouseEventHandler<HTMLDivElement>
}

export const ItemCard = ({ itemId, display, handleClose }: ItemCardProps) => {
    const [item, setItem] = useState(null as ItemOutput | null)
    useEffect(() => {
        if (display) {
            getCardItem(itemId).then((data) => {
                setItem(data)
            })
        }
    }, [display, itemId])

    return (
        <>
            <div
                style={{ display: display ? "flex" : "none" }}
                className={classes["full-background"]}
                onClick={handleClose}
            ></div>
            <div className={classes.box} style={{ display: display ? "flex" : "none" }}>
                <h2>
                    {item?.product?.title ||
                        item?.product_page?.title_en ||
                        item?.accessory?.title_en}
                </h2>
                <img src={item?.variant?.picture || item?.accessory?.images[0]?.picture} alt="" />

                {item?.variant && (
                    <div className={classes.inline}>
                        {item.variant?.quantity ? (
                            <p>
                                Quantié (unité) : <span>{item.variant.quantity}</span>
                            </p>
                        ) : (
                            <p>
                                Poids :&nbsp; <span>{item.variant?.weight + " g"}</span>
                            </p>
                        )}
                        <p>
                            Packaging :&nbsp;
                            <span>{item.variant.packaging}</span>
                        </p>
                    </div>
                )}

                {item?.reference && (
                    <div className={classes.inline}>
                        <p>
                            Nom Easyprep :&nbsp;
                            <span>{item.reference.easyprep_name}</span>
                        </p>
                        <p>
                            Texture :&nbsp; <span> {item.reference.texture_en}</span>
                        </p>
                    </div>
                )}

                <div className={classes.inline}>
                    <p>
                        Parfum :&nbsp;
                        <span>{item?.ingredient_perfume?.name_fr || "No Perfum"}</span>
                    </p>
                </div>
                <div className={classes.list}>
                    {item?.ingredients.map((ingredient, index) => (
                        <IngredientCard
                            key={index}
                            name={ingredient.name_fr}
                            picture={ingredient.picture}
                        />
                    ))}
                </div>
                <div className={classes.inline}>
                    <p>
                        Quantité :&nbsp;
                        <span style={{ textAlign: "left" }}>{item?.quantity}</span>
                        Prix :&nbsp;
                        <span style={{ textAlign: "left" }}>{item?.price} €</span>
                    </p>
                </div>
            </div>
        </>
    )
}
