import { useEffect, useState } from "react"
import { ItemOutput, UpdateOrderItemInput } from "../../Services/orders.service"
import { IngredientItem, PremixItem } from "../Ingredient/Item"
import classes from "./Common.module.scss"
import CancelIcon from "@material-ui/icons/Cancel"
import { SelectInput } from "../Input/SelectInput"
import { getProduct, ProductOutput } from "../../Services/products.service"
import { getFullName } from "../../utils/utils"

interface ProductionItemProps {
    item: ItemOutput
    readOnly: boolean
    handleCloseItem: React.MouseEventHandler<HTMLButtonElement>
    handleChangeItem: (data: UpdateOrderItemInput) => void
}

interface SelectOption {
    label: string
    value: number
}

interface ActiveProps {
    id: number
    name: string
    picture: string
    selected: boolean
    is_recommanded?: boolean
    is_history?: boolean
}

interface PremixProps {
    id: number
    name: string
}

export const ProductionItem = ({
    item,
    handleCloseItem,
    handleChangeItem,
    readOnly,
}: ProductionItemProps) => {
    const [product, setProduct] = useState({} as ProductOutput)
    const [modified, setModified] = useState(false)

    const [ingredients, setIngredients] = useState([] as ActiveProps[])
    const [premixs, setPremixs] = useState([] as PremixProps[])
    const [perfumOptions, setPerfumOptions] = useState([] as SelectOption[])
    const [textureOptions, setTextureOptions] = useState([] as SelectOption[])
    const [variantOptions, setVariantOptions] = useState([] as SelectOption[])

    const [selectedPerfum, setSelectedPerfum] = useState({} as SelectOption)
    const [selectedTexture, setSelectedTexture] = useState({} as SelectOption)
    const [selectedVariant, setSelectedVariant] = useState({} as SelectOption)
    const [selectedIngredients, setSelectedIngredients] = useState([] as number[])

    const handleClickIngredient = (id: number) => {
        return (selected: boolean) => {
            if (selected) {
                setSelectedIngredients([...selectedIngredients, id])
            } else {
                setSelectedIngredients(selectedIngredients.filter((i) => i !== id))
            }
            setModified(true)
        }
    }

    const handlePerfumChange = (option: SelectOption) => {
        setModified(true)
        setSelectedPerfum(option)
    }
    const handleVariantChange = (option: SelectOption) => {
        setModified(true)
        setSelectedVariant(option)
    }
    const handleTextureChange = (option: SelectOption) => {
        setModified(true)
        setSelectedTexture(option)
    }

    useEffect(() => {
        if (product.references) {
            const excluded_ref_ids =
                product.variants
                    .filter((variant) => variant.id === selectedVariant.value)[0]
                    ?.excluded_references.map((ref) => ref.id) || []
            setTextureOptions(
                product.references
                    .filter((ref) => !excluded_ref_ids.includes(ref.id))
                    .map((ref) => ({
                        label: ref.texture_fr + " " + ref.easyprep_name,
                        value: ref.id,
                    }))
            )
        }
        // eslint-disable-next-line
    }, [selectedVariant])

    useEffect(() => {
        const productId = item.product
            ? item.product.id
            : item.product_page
            ? item.product_page.product.id
            : null

        if (!!productId) {
            getProduct(productId).then((product) => {
                setProduct(product)
                setPerfumOptions([
                    ...product.perfumes.map((p) => ({ label: p.name_fr, value: p.id })),
                    { label: "Sans parfum", value: 0 },
                ])
                setSelectedPerfum(
                    item.ingredient_perfume
                        ? {
                              label: item.ingredient_perfume.name_fr,
                              value: item.ingredient_perfume.id,
                          }
                        : { label: "Sans parfum", value: 0 }
                )
                setVariantOptions(
                    product.variants.map((v) => ({
                        label: v.packaging + " " + (v.quantity || v.weight),
                        value: v.id,
                    }))
                )
                if (item.variant) {
                    const variant = {
                        label: [
                            item.variant.packaging,
                            item.variant.quantity || item.variant.weight,
                        ].join(" "),
                        value: item.variant.id,
                    }
                    const excluded_ref_ids =
                        product.variants
                            .filter((v) => v.id === variant.value)[0]
                            ?.excluded_references.map((ref) => ref.id) || []

                    setSelectedVariant(variant)
                    setTextureOptions(
                        product.references
                            .filter((ref) => !excluded_ref_ids.includes(ref.id))
                            .map((ref) => ({
                                label: ref.texture_fr + " " + ref.easyprep_name,
                                value: ref.id,
                            }))
                    )
                } else {
                    setTextureOptions(
                        product.references.map((ref) => ({
                            label: ref.texture_fr + " " + ref.easyprep_name,
                            value: ref.id,
                        }))
                    )
                }

                if (item.reference) {
                    const reference = product.references.filter(
                        (ref) => ref.id === item.reference?.id
                    )[0]

                    if (reference) {
                        setSelectedTexture({
                            label: reference.texture_fr + " " + reference.easyprep_name,
                            value: reference.id,
                        })

                        const newIngredients = reference.preparations
                            .filter((prep) => prep.ingredient?.visible)
                            .map((prep) => ({
                                id: prep.ingredient.id,
                                picture: prep.ingredient.picture,
                                name: getFullName(prep.ingredient.name_fr, prep.label),
                                is_recommanded:
                                    item.recommanded_ingredients.filter(
                                        (id) => id === prep.ingredient.id
                                    ).length > 0,
                                is_history:
                                    item.history_ingredients.filter(
                                        (id) => id === prep.ingredient.id
                                    ).length > 0,
                                selected: item.ingredients
                                    .map((elt) => elt.id)
                                    .includes(prep.ingredient.id),
                            }))

                        const newPremixs = reference.preparations
                            .filter((prep) => !!prep.premix)
                            .map((prep) => ({
                                id: prep.premix.id,
                                name: prep.premix.easyprep_name,
                            }))
                        const newSelectedIngredients = reference.preparations
                            .filter((prep) => prep.ingredient?.visible)
                            .filter((prep) =>
                                item.ingredients.map((elt) => elt.id).includes(prep.ingredient.id)
                            )
                            .map((prep) => prep.ingredient.id)
                        console.log(newIngredients)
                        setIngredients(newIngredients)
                        setPremixs(newPremixs)
                        setSelectedIngredients(newSelectedIngredients)
                    }
                }
            })
        }
    }, [item])

    useEffect(() => {
        if (modified) {
            const itemData: UpdateOrderItemInput = {
                variant_id: selectedVariant.value,
                reference_id: selectedTexture.value,
                ingredients_ids_list: selectedIngredients,
            }
            if (selectedPerfum.value !== 0) itemData.perfume_id = selectedPerfum.value
            handleChangeItem(itemData)
        }
        // eslint-disable-next-line
    }, [modified, selectedVariant, selectedTexture, selectedPerfum, selectedIngredients])

    return (
        <div className={classes["production-item"]}>
            <section>
                <img src={item.variant?.picture} alt="" />
                <h2>
                    {item.product
                        ? "Produit recommandé"
                        : item.product_page
                        ? "Produit Sur étagère"
                        : "Accessoire"}
                    &nbsp;&nbsp;&nbsp;
                    <span>
                        {item.product?.title ||
                            item.product_page?.title_fr ||
                            item.accessory.title_fr}
                    </span>
                </h2>
                {!readOnly && (
                    <button onClick={handleCloseItem}>
                        <CancelIcon />
                    </button>
                )}
            </section>
            <section>
                <h3>
                    Nombre maximum d'ingrédients : <span>{product.max_benefits}</span>
                </h3>
            </section>
            <section style={{ flexWrap: "wrap" }}>
                {ingredients.map((ingredient, index) => (
                    <IngredientItem
                        key={index}
                        id={ingredient.id}
                        name={ingredient.name}
                        picture={ingredient.picture}
                        selected={ingredient.selected}
                        onClick={handleClickIngredient(ingredient.id)}
                        readOnly={readOnly}
                        is_recommanded={ingredient.is_recommanded}
                        is_history={ingredient.is_history}
                    />
                ))}
                {premixs.map((premix) => (
                    <PremixItem key={premix.id} id={premix.id} name={premix.name} />
                ))}
            </section>
            {(!!item.product || !!item.product_page) && (
                <section>
                    <SelectInput
                        label="Parfum"
                        options={perfumOptions}
                        option={selectedPerfum}
                        onChange={handlePerfumChange}
                        readOnly={readOnly}
                    />
                    <SelectInput
                        label="Variant"
                        options={variantOptions}
                        option={selectedVariant}
                        onChange={handleVariantChange}
                        readOnly={readOnly}
                    />
                    <SelectInput
                        label="Texture"
                        options={textureOptions}
                        option={selectedTexture}
                        onChange={handleTextureChange}
                        readOnly={readOnly}
                    />
                </section>
            )}
        </div>
    )
}
