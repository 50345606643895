import Select from "react-select"
import classes from "./SelectInput.module.scss"

export interface SelectInputProps {
    label: string
    option: {
        label: string
        value: string | number
    }
    options: {
        label: string
        value: string | number
    }[]
    readOnly: boolean
    onChange: (value: any) => void
}

export const SelectInput = ({ label, option, options, onChange, readOnly }: SelectInputProps) => {
    return (
        <div className={classes.main}>
            <label>{label}</label>
            <Select
                isDisabled={readOnly}
                options={options}
                value={option}
                onChange={onChange}
                styles={{
                    container: (provided) => ({ ...provided, width: "200px", margin: 10 }),
                    menuList: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                        backgroundColor: "white",
                        width: "200px",
                    }),
                }}
            />
        </div>
    )
}
