import { useState } from "react"
import classes from "./Item.module.scss"
import SettingsIcon from "@mui/icons-material/Settings"
import HistoryIcon from "@mui/icons-material/History"

export interface PremixProps {
    id: number
    name: string
}
export interface IngredientItemProps extends PremixProps {
    picture: string
    selected: boolean
    onClick: (selected: boolean) => void
    readOnly: boolean
    is_history?: boolean
    is_recommanded?: boolean
}

export const PremixItem = ({ id, name }: PremixProps) => {
    return (
        <button className={classes.main} style={{ opacity: 1 }}>
            <p>{name}</p>
        </button>
    )
}

export const IngredientItem = ({
    id,
    picture,
    name,
    selected,
    readOnly,
    onClick,
    is_history,
    is_recommanded,
}: IngredientItemProps) => {
    const [isSelected, setIsSelected] = useState(selected)

    const handleClick: React.MouseEventHandler<HTMLButtonElement> = () => {
        if (!readOnly) {
            onClick(!isSelected)
            setIsSelected(!isSelected)
        }
    }
    return (
        <button
            className={classes.main}
            onClick={handleClick}
            style={{ opacity: isSelected ? 1 : 0.25 }}
        >
            {is_recommanded && <SettingsIcon color="secondary" className={classes.picto_1} />}
            {is_history && <HistoryIcon color="error" className={classes.picto_2} />}
            <p>{name}</p>
            <img src={picture} alt="" />
        </button>
    )
}
