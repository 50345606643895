import classes from "./Common.module.scss"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { Typography } from "@material-ui/core"

interface AccordionProps {
    name: string
    children: JSX.Element
    expanded: boolean
    disabled?: boolean
}

export const AccordionCard = ({ name, children, expanded, disabled }: AccordionProps) => {
    return (
        <Accordion className={classes.accordion} defaultExpanded={expanded}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                disabled={!!disabled}
            >
                <Typography>{name}</Typography>
            </AccordionSummary>
            <AccordionDetails>{children}</AccordionDetails>
        </Accordion>
    )
}
