import React, { FormEvent, useState } from "react"
import classes from "./Login.module.scss"
import { loginUser } from "../../Services/authentication.service"
import { ACCESS_TOKEN, REFRESH_TOKEN } from "../../Services/api.service"
import { useUser } from "../../Contexts/user.context"
import { useHistory } from "react-router-dom"
import { SimpleButton } from "../Button"
import { SimpleInput } from "../Input"
import { Alert } from "react-bootstrap"
import { useEffect } from "react"

const Login = () => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [error, setError] = useState(null as null | string)
    const { userInfo, setUserInfo } = useUser()
    const history = useHistory()

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        try {
            const { access_token, refresh_token, user } = await loginUser({ email, password })
            localStorage.setItem(ACCESS_TOKEN, access_token)
            localStorage.setItem(REFRESH_TOKEN, refresh_token)
            setUserInfo({ ...userInfo, ...user, id: user.pk })
            history.push("/")
        } catch (e) {
            const key = Object.keys(e.response?.data)[0]
            setError(key + " : " + e.response?.data[key])
        }
    }

    useEffect(() => {
        if (userInfo.id) history.push("/")
    }, [userInfo, history])

    return (
        <div className={classes.center}>
            {!!error && (
                <Alert variant="danger" id="alert">
                    {error}
                </Alert>
            )}
            <form className={classes["login-wrapper"]} onSubmit={(e) => handleSubmit(e)}>
                <SimpleInput
                    type="text"
                    label="Username"
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="email"
                />
                <SimpleInput
                    type="password"
                    label="Password"
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="mot de passe"
                />
                <SimpleButton value={"Submit"} />
            </form>
        </div>
    )
}

export default Login
