import HelpOutlineIcon from "@material-ui/icons/HelpOutline"
import { MouseEventHandler, useState } from "react"
import { Alert } from "react-bootstrap"
import classes from "./QuestionMark.module.scss"

interface QuestionMarkProps {
    helpText: string
}
export const QuestionMark = ({ helpText }: QuestionMarkProps) => {
    const [show, setShow] = useState(false)

    const handleMouseEnter: MouseEventHandler<HTMLButtonElement> = () => {
        setShow(true)
    }
    const handleMouseLeave: MouseEventHandler<HTMLButtonElement> = () => {
        setShow(false)
    }
    return (
        <button
            className={classes.main}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <HelpOutlineIcon className={show ? classes.active : classes.inactive} />
            <Alert
                variant="danger"
                className={classes.alert}
                style={{ display: show ? "inline-block" : "none" }}
            >
                {helpText}
            </Alert>
        </button>
    )
}
