import "./App.scss"
import { ShopProvider } from "./Contexts/shop.context"
import { UserProvider } from "./Contexts/user.context"
import { Navigation } from "./Navigation/Navigation"

function App() {
    return (
        <UserProvider>
            <ShopProvider>
                <div className="App">
                    <Navigation />
                </div>
            </ShopProvider>
        </UserProvider>
    )
}

export default App
