import React, { useState, createContext } from "react"

type UserInfo = {
    id: number | null
    email?: string | null
    first_name?: string | null
    last_name?: string | null
}

const UserContext = createContext<
    { userInfo: UserInfo; setUserInfo: React.Dispatch<React.SetStateAction<UserInfo>> } | undefined
>(undefined)
const UserProvider: React.FC = (props) => {
    const [userInfo, setUserInfo] = useState<UserInfo>({
        id: null,
    })

    return (
        <UserContext.Provider
            value={{
                userInfo,
                setUserInfo,
            }}
        >
            {props.children}
        </UserContext.Provider>
    )
}

export const useUser = () => {
    const context = React.useContext(UserContext)
    if (context === undefined) throw new Error("useUser must be used in userProvider")
    return context
}

export { UserContext, UserProvider }
