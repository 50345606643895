import classes from "./SimpleInput.module.scss"

interface InputProps {
    type: string
    label: string
    placeholder: string
    onChange: React.ChangeEventHandler<HTMLInputElement> | undefined
}

export const SimpleInput = ({ type, label, placeholder, onChange }: InputProps) => (
    <div className={classes["simple-wrapper"]}>
        <label>{label}</label>
        <input
            type={type}
            className={classes["simple-input"]}
            onChange={onChange}
            placeholder={placeholder}
        />
    </div>
)
