import React from "react"
import Select, { OptionsType, OptionTypeBase } from "react-select"
import makeAnimated from "react-select/animated"

const animatedComponents = makeAnimated()

interface FilteringProps {
    label: string
    width: number
    options: OptionsType<OptionTypeBase>
    filterBy: (filters: OptionTypeBase | null) => void
}

export const Filtering = ({ options, label, filterBy, width }: FilteringProps) => {
    return (
        <Select
            closeMenuOnSelect={true}
            components={animatedComponents}
            options={options}
            placeholder={label}
            isClearable
            styles={{
                // Fixes the overlapping problem of the component
                container: (provided) => ({
                    ...provided,
                    width,
                    zIndex: 9999,
                    padding: "6px",
                    height: 40,
                }),
                menuList: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                    position: "fixed",
                    backgroundColor: "white",
                    width: width - 75,
                }),
            }}
            onChange={filterBy}
        />
    )
}
