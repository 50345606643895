import { useEffect, useState } from "react"
import classes from "./Common.module.scss"
import { AnswersOutput, getCustomerAnswers } from "../../Services/customers.service"

interface AnswersProps {
    customerId: number
}

export const Answers = ({ customerId }: AnswersProps) => {
    const [answers, setAnswers] = useState([] as AnswersOutput[])

    useEffect(() => {
        getCustomerAnswers(customerId).then((data) => {
            if (data.length > 0) {
                setAnswers(data)
            }
        })
    }, [customerId])
    return (
        <div className={classes.answers}>
            {answers.map((answer) => (
                <div key={answer.id} className={classes.answer}>
                    <h2>{answer.question.question_fr}</h2>
                    <div className={classes.choices}>
                        {answer.choices.map((choice) => (
                            <span key={choice.id}>{choice.answer_fr}</span>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    )
}
