import { useEffect, useState } from "react"
import classes from "./Navigation.module.scss"
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom"
import { ClipLoader } from "react-spinners"
import Header from "../Components/Layout/Header"
import Login from "../Components/Login/Login"
import { useUser } from "../Contexts/user.context"
import { getUserInfo } from "../Services/authentication.service"
import { ProtectedRoute } from "./ProtectedRoute"
import CustomersTable from "../Components/Table/CustomersTable"
import OrdersTable from "../Components/Table/OrdersTable"
import { ClientOverView } from "../Components/Client"
import { OrderView } from "../Components/Order/OrderView"

export const Navigation = () => {
    const [loading, setLoading] = useState(true)
    const { userInfo, setUserInfo } = useUser()

    useEffect(() => {
        getUserInfo()
            .then((reponse) => {
                setUserInfo({ ...userInfo, ...reponse })
                setLoading(false)
            })
            .catch((error) => {
                setLoading(false)
            })
        // eslint-disable-next-line
    }, [])

    return (
        <>
            {loading && (
                <div className={classes.main}>
                    <div>
                        <ClipLoader color={"#7F8F83"} loading={loading} size={50} />
                    </div>
                </div>
            )}
            {!loading && (
                <>
                    <BrowserRouter>
                        {!!userInfo.id && <Header />}
                        <Switch>
                            <Route path="/login">
                                <Login />
                            </Route>
                            <ProtectedRoute path="/clients/:userId">
                                <ClientOverView />
                            </ProtectedRoute>
                            <ProtectedRoute path="/clients">
                                <CustomersTable />
                            </ProtectedRoute>
                            <ProtectedRoute path="/orders/:orderId">
                                <OrderView />
                            </ProtectedRoute>
                            <ProtectedRoute path="/orders">
                                <OrdersTable />
                            </ProtectedRoute>
                            <Redirect to="/clients" />
                        </Switch>
                    </BrowserRouter>
                </>
            )}
        </>
    )
}
