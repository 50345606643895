import React from "react"
import classes from "./Header.module.scss"
import { Link } from "react-router-dom"
import { Navbar, Nav, NavDropdown } from "react-bootstrap"
import labotePicture from "../../Assets/pictures/logo.png"
import { useUser } from "../../Contexts/user.context"
import { useShop } from "../../Contexts/shop.context"

const cn = require("classnames")

const Header = () => {
    const { userInfo, setUserInfo } = useUser()
    const { shopContext, setShopContext } = useShop()

    const logout = () => {
        localStorage.clear()
        window.location.href = "/"
        setUserInfo({ id: null })
    }

    return (
        <Navbar
            expand="md"
            variant="light"
            className={cn(classes.main, classes.background, "dark")}
        >
            <Navbar.Brand href="/" className={classes.logo}>
                <img src={labotePicture} alt="Labote" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse
                id="basic-navbar-nav"
                className={cn("justify-content-end", classes.correction)}
            >
                <Nav className={classes.nav}>
                    <Link className={classes.color} to="/clients">
                        Clients
                    </Link>
                    <Link className={classes.color} to="/orders">
                        Commandes
                    </Link>
                    <NavDropdown
                        title={
                            shopContext === "KELLER"
                                ? "Keller  "
                                : shopContext === "MADAME"
                                ? "Madame  "
                                : "Madame & Keller  "
                        }
                        id="basic-nav-dropdown"
                        style={{
                            padding: "0px 1 0px",
                        }}
                    >
                        <NavDropdown.Item onSelect={() => setShopContext("MADAME")}>
                            Madame
                        </NavDropdown.Item>
                        <NavDropdown.Item onSelect={() => setShopContext("KELLER")}>
                            Keller
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item onSelect={() => setShopContext(null)}>
                            Madame & Keller
                        </NavDropdown.Item>
                    </NavDropdown>
                    {!!userInfo.id ? (
                        <Link className={classes.color} to="/login" onClick={logout}>
                            Deconnexion
                        </Link>
                    ) : (
                        <Link className={classes.color} to="/login">
                            Connexion
                        </Link>
                    )}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default Header
