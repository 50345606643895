import classes from "./Common.module.scss"

interface ButtonProps {
    value: string
}

export const SimpleButton = ({ value }: ButtonProps) => (
    <div className={classes["simple-wrapper"]}>
        <button type="submit" className={classes["simple-button"]}>
            {value}
        </button>
    </div>
)
