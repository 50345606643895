import "moment/locale/fr"
import { FormEvent, useState } from "react"
import {
    createCustomerMemo,
    deleteCustomerMemo,
    MemoOutput,
} from "../../Services/customers.service"
import { Message } from "./Message"
import CreateIcon from "@material-ui/icons/Create"
import classes from "./Common.module.scss"

interface CustomerMemoProps {
    memos: MemoOutput[]
    customerId: number
}

export const CustomerMemo = ({ memos, customerId }: CustomerMemoProps) => {
    const [allMemos, setAllMemos] = useState(memos || [])
    const [newMessage, setNewMessage] = useState(undefined as undefined | string)
    const [open, setOpen] = useState(false)

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        try {
            if (newMessage) {
                const data = await createCustomerMemo(customerId.toString(), newMessage)
                setAllMemos([data, ...allMemos])
                setNewMessage(undefined)
                setOpen(!open)
            }
        } catch (e) {
            console.log(e)
        }
    }

    const removeMemo = async (id: number) => {
        try {
            await deleteCustomerMemo(id)
            setAllMemos(allMemos.filter((memo) => memo.id !== id))
        } catch (e) {
            console.log(e)
        }
    }

    const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setNewMessage(e.target.value)
    }

    const onClick: React.MouseEventHandler<HTMLButtonElement> = () => {
        setOpen(!open)
    }
    return (
        <div style={{ width: "100%" }}>
            {open ? (
                <Message
                    readOnly={false}
                    onChange={onChange}
                    handleSubmit={handleSubmit}
                    onClick={onClick}
                    deletable={true}
                />
            ) : (
                <button onClick={onClick} className={classes["no-style"]}>
                    <span>Ecrire une note</span>
                    <CreateIcon />
                </button>
            )}
            {allMemos
                .sort((a, b) => b.created_at - a.created_at)
                .map((memo, index) => (
                    <Message
                        id={memo.id}
                        key={index}
                        value={memo.message}
                        writtenAt={memo.created_at}
                        readOnly={true}
                        deletable={true}
                        removeMemo={removeMemo}
                    />
                ))}
        </div>
    )
}
