import moment from "moment"
import "moment/locale/fr"
import classes from "./Common.module.scss"

interface ClientCardProps {
    email: string
    firstname: string
    lastname: string
    picture: string
    birthdate: Date
    phone_number: string
}

export const ClientCard = ({
    email,
    phone_number,
    firstname,
    lastname,
    picture,
    birthdate,
}: ClientCardProps) => {
    const localBirthdate = moment(birthdate)
    localBirthdate.locale("fr")
    return (
        <div className={classes["client-card"]}>
            <img src={picture} alt=""></img>
            <h3>{`${firstname} ${lastname}`}</h3>
            {birthdate && <p>&#8226; Né le : {localBirthdate.format("DD MMMM YYYY")}</p>}
            {birthdate && (
                <p>
                    &#8226; Age : {moment().diff(localBirthdate.format("DD MMMM YYYY"), "years")}{" "}
                    ans
                </p>
            )}

            <p>&#8226; Email : {email}</p>
            <p>&#8226; Tèl : {phone_number}</p>
        </div>
    )
}
