import { ChartOutput } from "../../Services/customers.service"
import classes from "./Common.module.scss"

interface RadarProps {
    charts: ChartOutput[]
}

export const Radar = ({ charts }: RadarProps) => {
    return (
        <div className={classes.charts}>
            {charts.map((chart, index) => (
                <div key={index} className={classes.chart}>
                    <h2>{chart.scale.name_fr}</h2>
                    <div className={classes["progress-bar"]}>
                        <div className={classes.container} style={{ width: `${chart.value}%` }} />
                        <span className={classes.left}>{chart.scale.left_edge_fr}</span>
                        <span className={classes.right}>{chart.scale.right_edge_fr}</span>
                    </div>
                </div>
            ))}
        </div>
    )
}
