import axios from "axios"

export const ACCESS_TOKEN = "access_token"
export const REFRESH_TOKEN = "refresh_token"
export const BASE_DOMAIN = process.env.REACT_APP_DOMAIN

const api = axios.create({
    baseURL: BASE_DOMAIN,
    headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
    },
})

const createAxiosResponseInterceptor = () => {
    const interceptor = api.interceptors.response.use(
        (response) => response,
        (error) => {
            // Reject promise if usual error
            if (error.response.status !== 401) {
                return Promise.reject(error)
            }

            /*
             * When response code is 401, try to refresh the token.
             * Eject the interceptor so it doesn't loop in case
             * token refresh causes the 401 response
             */
            api.interceptors.response.eject(interceptor)

            return api
                .post("api/token/refresh/", {
                    refresh: localStorage.getItem(REFRESH_TOKEN),
                })
                .then((response) => {
                    localStorage.setItem(ACCESS_TOKEN, response.data.access)
                    error.response.config.headers.Authorization = `Bearer ${response.data.access}`
                    return axios(error.response.config)
                })
                .catch((error) => {
                    localStorage.removeItem(ACCESS_TOKEN)
                    localStorage.removeItem(REFRESH_TOKEN)
                    return Promise.resolve({ data: null })
                })
                .finally(createAxiosResponseInterceptor)
        }
    )
}

createAxiosResponseInterceptor()

export default api
