import { PaginationInterface } from "./paginations.interface"
import { del, get, post } from "./utils.service"

export interface CustomersListInput {
    page: number
    page_size: number
    ordering?: string
    search?: string
}

export interface MemoOutput {
    id: number
    created_at: number
    message: string
}

export interface ScaleOutput {
    name_fr: string
    min_value: number
    max_value: number
    left_edge_fr: string
    right_edge_fr: string
}
export interface ChartOutput {
    value: number
    scale: ScaleOutput
}

export interface LightCustomerOutput {
    id: number
    first_name: string
    last_name: string
    email: string
    phone_number: string
    birthdate: Date
    skin_picture: string
    gender: "MALE" | "FEMALE"
}
export interface CustomerOutput extends LightCustomerOutput {
    internal_memos: MemoOutput[]
    charts: ChartOutput[]
}

export interface AnswersOutput {
    id: number
    question: { id: number; question_fr: string }
    choices: { id: number; answer_fr: string }[]
}

export type CustomersListOutput = PaginationInterface<CustomerOutput>

export const getCustomersList = async (input: CustomersListInput) => {
    const response = await get("/api/admin-customer/", input)
    return response.data as CustomersListOutput
}

export const getCustomer = async (id: number) => {
    const response = await get(`/api/admin-customer/${id}`)
    return response.data as CustomerOutput
}

export const createCustomerMemo = async (customer: string, message: string) => {
    const response = await post("/api/admin-customer-memo/", { message, customer })
    return response.data as MemoOutput
}

export const deleteCustomerMemo = async (id: number) => {
    const response = await del(`/api/admin-customer-memo/${id}/`)
    return response.data
}

export const getCustomerAnswers = async (customer_id: number) => {
    const response = await get(`/api/admin-customer-answer/`, { customer_id })
    return response.data as AnswersOutput[]
}

export const updateCustomerDiag = async (customer_id: number) => {
    return post(`/api/admin-customer/${customer_id}/update/`, { customer_id })
}
