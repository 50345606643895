import { useState } from "react"
import { useEffect } from "react"
import { useParams } from "react-router-dom"
import { ClipLoader } from "react-spinners"
import { DetailledOrderOutput, getCustomerOrder } from "../../Services/orders.service"
import { ProductionOrder } from "./ProductionOrder"
import classes from "./Common.module.scss"
import { AccordionCard } from "../Client/Accordion"
import { CustomerMemo } from "../Client/CustomerMemo"
import { Answers } from "../Client/Anwsers"
import { Radar } from "../Client/Radar"
import { ClientCard } from "../Client/Card"

interface ParamsType {
    orderId: string
}

export const OrderView = () => {
    const { orderId } = useParams<ParamsType>()
    const [loading, setLoading] = useState(true)

    const [order, setOrder] = useState(null as null | DetailledOrderOutput)

    useEffect(() => {
        setLoading(true)
        const id = parseInt(orderId)
        if (!!id)
            getCustomerOrder(id).then((data) => {
                setOrder(data)
                setLoading(false)
            })
    }, [orderId])
    return (
        <>
            {!loading && !!order?.id ? (
                <div className={classes.main}>
                    <ClientCard
                        email={order.customer.email}
                        phone_number={order.customer.phone_number}
                        firstname={order.customer.first_name}
                        lastname={order.customer.last_name}
                        birthdate={order.customer.birthdate}
                        picture={order.customer.skin_picture}
                    />
                    <AccordionCard name="Note Interne" expanded={false}>
                        <CustomerMemo
                            customerId={order.customer.id}
                            memos={order.customer.internal_memos}
                        />
                    </AccordionCard>
                    <AccordionCard name="Réponses au quizz" expanded={false}>
                        <Answers customerId={order.customer.id} />
                    </AccordionCard>
                    <AccordionCard name="Bilan cutané" expanded={false}>
                        <Radar charts={order.customer.charts || []} />
                    </AccordionCard>
                    <AccordionCard
                        name={`Fiche de fabrication - commande n°${order.id} - à ${order.preparation_place}`}
                        expanded={true}
                        disabled={true}
                    >
                        <ProductionOrder order={order} readOnly={order.status !== "PAID"} />
                    </AccordionCard>
                </div>
            ) : (
                <div className={classes.loading}>
                    <div>
                        <ClipLoader color={"#7F8F83"} loading={loading} size={50} />
                    </div>
                </div>
            )}
        </>
    )
}
