import React, { useState, createContext } from "react"

type ShopType = "MADAME" | "KELLER" | null

const ShopContext = createContext<
    | {
          shopContext: ShopType
          setShopContext: React.Dispatch<React.SetStateAction<ShopType>>
      }
    | undefined
>(undefined)

const ShopProvider: React.FC = (props) => {
    const [shopContext, setShopContext] = useState<ShopType>(null)
    return (
        <ShopContext.Provider
            value={{
                shopContext,
                setShopContext,
            }}
        >
            {props.children}
        </ShopContext.Provider>
    )
}

export const useShop = () => {
    const context = React.useContext(ShopContext)
    if (context === undefined) throw new Error("useUser must be used in userProvider")
    return context
}

export { ShopProvider, ShopContext }
